<template>
    <div id="results">
        <b-row>
            <b-col cols="12">
                <div class="results-filters">
                    <!--<ResultsHead :total="total" :geoLabel="geoLabel" :possibleProperties="possibleProperties" />-->
                    <ActiveFilters />
                </div>
                <div class="results-sort mobile-only">

                    <p class="common-title">
                        <span v-if="total==1">Migliore struttura su </span>
                        <span v-else><strong>{{total}} strutture</strong> su </span> {{possibleProperties}} totali
                    </p>

                    <b-dropdown right id="dropdown-sort" ref="dropdown-sort" variant="light" size="sm" no-caret>
                        <template v-slot:button-content>
                            <span>{{activeSortLabel}}</span>
                            <SvgChevronDown />
                        </template>
                        <span class="dropdown-item" @click="sortByDropdown('defaultSorting','ASC','Default')" :active="sortResults.key == 'none' && sortResults.order == 'ASC'">Default</span>
                        <span class="dropdown-item" @click="sortByDropdown('proximityDistance','ASC','Distanza')" :active="sortResults.key == 'proximityDistance' && sortResults.order == 'ASC'">Distanza</span>
                        <span class="dropdown-item" @click="sortByDropdown('live_price','ASC','Prezzo più basso')" :active="sortResults.key == 'live_price' && sortResults.order == 'ASC'" >Prezzo più basso</span>
                        <span class="dropdown-item" @click="sortByDropdown('live_price','DESC','Prezzo più alto')" :active="sortResults.key == 'live_price' && sortResults.order == 'DESC'" >Prezzo più alto</span>
                        <span class="dropdown-item" @click="sortByDropdown('salabamScore','DESC','Rating')" :active="sortResults.key == 'salabamScore' && sortResults.order == 'ASC'" >Rating</span>
                    </b-dropdown>
                </div>

                <div class="filters mobile-only">
                    <div v-if="this.$config.integration.availability > 0" class="filter-availability"
                        v-b-popover.hover.top="'Con l\'interruttore accesso vedrai solo strutture con costo uguale o inferiore al tuo credito'+(mixinIsWelfare() ? ' welfare':'')+'. Con l\'interruttore spento vedi tutte le strutture disponibili.'"
                    >
                        <div class="filter-availability-info">
                            <!--<SvgInfoCircle />-->
                            <p>
                                Mostra in base a
                                <span v-if="mixinIsWelfare()">Credito Welfare</span><span v-else>Credito</span>
                            </p>
                        </div>
                        <CustomSwitch
                            :status="welfareAvailability"
                            :switchUniqueKey="'2'"
                            v-on:switch-change="checked => this.switchedAvailabilityFilter(checked)"
                        />
                    </div>

                    <!-- và rivisto il funzionamento da backend altrimenti filtra solo i risultati in base alla tariffa usata nei risultati -->
                    <!--<div class="filter-refundable">
                    <div class="filter-refundable-info" v-b-popover.hover.top="'Ti mostreremo solamente soluzioni rimborsabili'">
                        <p>
                        Solo camere rimborsabili
                        </p>
                    </div>
                    <CustomSwitch
                        :status="selectedAmenities.includes('refundable')"
                        :switchUniqueKey="'1'"
                        v-on:switch-change="checked => switchChanged(checked)"
                    />
                    </div>-->
                </div>
            </b-col>
        </b-row>

        <b-row>
            <b-col v-if="loading" cols="12"><Spinner /></b-col>

                <div class="scroller-wrap" v-else>

                    <!--
                    <b-col cols="12" class="result ghost-pad" v-if="properties.length">
                        <PropertyPad
                            :property="properties[0]"
                            :index="0"
                            :showShortDescription="true"
                        />
                    </b-col>                        
                    -->

                    <DynamicScroller
                        v-if="!loading/*ghostPadHeight*/"
                        page-mode
                        ref='resultsListScroller'
                        :items="properties"
                        :item-size="null/*ghostPadHeight*/"
                        :min-item-size="100/*350*/"
                        key-field="property_id"
                        class="scroller"
                    >
                        <template v-slot="{ item, index, active }">
                            <DynamicScrollerItem
                                :item="item"
                                :data-index="index"
                                :active="active"
                                :size-dependencies="[
                                    //item.thumbNailUrl,
                                    //item.shortDescription,
                                ]"
                            >
                                <b-col cols="12" class="result" :style="'order:'+(index+1)+';'">
                                    <PropertyPad
                                        :property="item"
                                        :index="index"
                                        :showShortDescription="true"
                                    />
                                </b-col>                        
                            </DynamicScrollerItem>
                        </template>
                    </DynamicScroller>
                </div>

                <!--
                    il v-if del b-col nearby è stato copiato da salabam e leggermente modificato

                    quello originale di questa pagina era
                    v-if="defaultResultsLength < config.searchSettings.searchSuggestionMinResults && matchLevel != 'nearby' && sortResults.by == 'defaultSorting' && defaultResultsLength > -1"
                -->

            <b-col

                v-if="matchLevel != 'nearby' &&
                (
                  (
                       defaultResultsLength > -1 // ci sono risultati prima chiamata
                    && ( defaultResultsLength <= (properties.length - defaultResultsLength) ) // questo serve per gestire nessun risultato nella seconda chiamata, cosi non mette la pad in coda visto che ci sono risultati solo alla prima chiamata
                  )

                  ||

                  // oppure
                  // nessun risultato prima chiamata, risultati nella seconda (pad al primo posto)
                  (
                       defaultResultsLength == -1
                    && properties.length >= 1
                  )

                )"

                cols="12"
                class="result"
                :style="'order:'+(defaultResultsLength)+';'"
            >
                <SearchSuggestion v-if="defaultResultsLength > 0" :label="'Magari ti interessa scoprire cosa è disponibile anche nei dintorni di <strong>' + currentLabel + '</strong>...'" />
                <SearchSuggestion v-else :label="'Spiacenti! Non ci sono disponibilità a <strong>' + currentLabel + '</strong>.<br/> Magari ti interessa qualcosa nei dintorni?'"/>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import Vue from 'vue'
import ActiveFilters from '../blocks/active-filters'
import Spinner from '../atoms/spinner'
import PropertyPad from '../blocks/property/property-pad'
import SearchSuggestion from '@/components/blocks/search-suggestion'
import SvgChevronDown from '../svg/chevron-down'
//import SvgInfoCircle from '../svg/info-circle'
import CustomSwitch from '../atoms/custom-switch'

export default {
    props: {
        properties: Array,
        possibleProperties: {
          type: Number,
          default: 0,
        },
        geoLabel: String,
        //activeBand: String,
        total: Number,
        defaultResultsLength: Number,
        //focus: String
    },
    data()
    {
        return {
            config: Vue.prototype.$config,
            //activeSort: 'live_price',
            activeSortLabel: 'Ordina per',
            //activeSort: 'none',
            //orderSort: 'ASC',
            loading: false,
            //ghostPadHeight: 0,
            currentLabel: this.geoLabel,
        }
    },
    computed: {
        geo: {
            get() { return this.$store.state.geo },
            set(value) { this.mixinSendMutation('setGeo',value) }
        },
        selectedAmenities: {
            get() {
                return this.$store.state.amenities
            },
            set(value) {
                this.mixinSendMutation('setAmenities',value)
            }
        },
        selectedCategories: {
            get() {
                return this.$store.state.categories
            },
            set(value) {
                this.mixinSendMutation('setCategories',value)
            }
        },
        scroll: {
            get() { return this.$store.state.scroll },
            set() {}
        },
        matchLevel: {
            get() { return this.$store.state.geo.matchLevel },
            set() {}
        },
        sortResults: {
            get() { return this.$store.state.sortResults },
            set() {}
        },
        sortResultsKey: {
            get() { return this.sortResults.by+'-'+this.sortResults.order },
            set() {}
        },
        welfareAvailability: {
            get() {
                return this.$store.state.welfareAvailability
            },
            set(value) {
                this.mixinSendMutation('setWelfareAvailability',value)
            }
        },
    },
    components:
    {
        //SvgInfoCircle,
        SvgChevronDown,
        Spinner,
        SearchSuggestion,
        PropertyPad,
        ActiveFilters,
        CustomSwitch,
        //ResultsHead,
    },
    created () {
        window.console.log('[search-results] created')
    },    
    mounted(){
        window.console.log('[search-results] mounted')
        window.addEventListener('resize', this.getDimensions);
        let _this = this
        _this.loading = true

        //setTimeout(function(){
        _this.properties = _this.mixinSortResults(_this.properties,_this.sortResults.by,_this.sortResults.order)
        //_this.ghostPadHeight = (document.getElementsByClassName('ghost-pad')[0])? document.getElementsByClassName('ghost-pad')[0].clientHeight : 0
        _this.loading = false
        //},50)
    },
    unmounted() {
        window.removeEventListener('resize', this.getDimensions);
    },
    beforeDestroy: function () {
        //window.console.log('[search-results] beforeDestroy')
    },
    destroyed () {
        //window.console.log('[search-results] destroyed')
    },
    methods:
    {
        // mobile -> dropdown dentro results
        sortByDropdown: function(by,order,label)
        {
            this.$refs['dropdown-sort'].hide()
            if (by == this.sortResults.by && order == this.sortResults) return

            if(this.$gtm) this.$gtm.trackEvent({
              event: 'customEvent', // Event type [default = 'interaction'] (Optional)
              category: 'userBehaviour',
              action: 'sortBy',
              //label: key,
              //value: this.bookingData.live_price,
              //noninteraction: false // Optional
            });
            this.mixinSendMutation('setSortResults',{
                by: by,
                order: order,
                label: label
            })
            //this.orderSort = order
            //this.activeSort = by
            //this.activeSortLabel = label
        },
        // desktop -> radio dentro accordion
        sortBy: function(key,order)
        {
            //this.$refs['dropdown-sort'].hide()
            //if (key == this.activeSort && order == this.orderSort) return
            if(this.$gtm) this.$gtm.trackEvent({
              event: 'customEvent', // Event type [default = 'interaction'] (Optional)
              category: 'userBehaviour',
              action: 'sortBy',
              //label: key,
              //value: this.bookingData.live_price,
              //noninteraction: false // Optional
            });

            let _this = this
            _this.loading = true
            setTimeout(function(){
                _this.properties = _this.mixinSortResults(_this.properties,key,order)
                _this.loading = false
            },50)
        },
        switchChanged(value)
        {
            // TODO trick - ci sono 2 refundable all'inizio nell'array
            // e fa so che vengano mostrate sempre e cmq solo refundable
            //this.selectedAmenities.pop('refundable')
            //this.selectedAmenities.pop('refundable')
            if(value)
            {
                this.selectedAmenities.push('refundable')
            }
            else
            {
                this.selectedAmenities.pop('refundable')
            }

            this.$parent.$emit('filter-changed',{
                selectedAmenities: this.selectedAmenities,
                selectedCategories: this.selectedCategories
            })
        },
        switchedAvailabilityFilter(checked)
        {
            this.mixinSendMutation('setWelfareAvailability',checked)
            this.triggerFilterChange()
        },
        triggerFilterChange()
        {
            let _this = this
            setTimeout(function(){
                _this.$parent.$emit('filter-changed') //evento emesso direttamente verso sarch.vue ($parent.$parent)
            },50)
        },
        /*
        getDimensions()
        {
            this.ghostPadHeight = (document.getElementsByClassName('ghost-pad')[0])? document.getElementsByClassName('ghost-pad')[0].clientHeight : 0
        },
        */
    },
    watch:{
        sortResultsKey: function() {
            this.sortBy(this.sortResults.by,this.sortResults.order,this.sortResults.label)
        },
    },
}
</script>

<style lang="scss">
    
    
    
    

    #search-results
    {
        @include media-breakpoint-up(xl)
        {
            padding-bottom:$grid-gutter-width/2;
        }
    }
    #results
    {
        padding-left:0;
        padding-right:0;
        .result
        {
            padding-bottom:$grid-gutter-width/2;
            margin-bottom:0;
        }
        .results-head
        {
            //margin-top:$grid-gutter-width/2;
            display:flex;
        }
        .results-filters
        {
            flex:1;
            //margin-top:1rem;
            @include media-breakpoint-down(sm)
            {
                .common-title {font-size:15px;}
            }
        }
        .results-sort
        {
            display: flex;
            align-items:center;
            margin-bottom:1rem;
            .common-title
            {
                font-weight: $font-weight-normal;
                font-size: 11px;
                padding-right:1rem;
            }
            #dropdown-sort
            {
                button
                {
                    min-width: 70px;
                    padding:6px 8px;
                    display:flex;
                    align-items: center;
                    margin:0 auto;
                }
                svg{max-width:10px}
                box-shadow:none;
                @include media-breakpoint-up(lg)
                {
                    //box-shadow:none;
                    button
                    {
                        //box-shadow:none;
                        padding-left: 1rem;
                        padding-right: 1rem;
                    }
                }
            }
            #dropdown-sort
            {
                *
                {
                    font-size: 12px !important;
                    line-height: 140%;
                    font-weight: 400;
                }
                a:focus {
                    background-color: transparent;
                }
                .dropdown-item
                {
                    white-space: normal;
                    padding:6px 14px;
                    transition:$transition-base;
                    cursor:pointer;
                    &:not(:last-child)
                    {
                        border-bottom:1px solid #dedede;
                    }
                    &:hover,
                    &:focus
                    {
                        background:rgba($secondary,0.15);
                        //color:$white;
                    }
                    &[active]
                    {
                        @include customize-background-color("secondary");
                        color:$white;
                    }
                }
                @include media-breakpoint-up(lg)
                {
                    *
                    {
                        font-size: 16px !important;
                    }
                    a
                    {
                        font-size: 14px !important;
                    }
                    button
                    {
                        padding:10px 16px;
                    }
                }
            }            
        }

        .common-title
        {
            @include media-breakpoint-up(lg)
            {
                display:flex;
                align-items: center;
            }
        }
        .map-link a
        {
            font-size:14px;
            @include customize-color("secondary");
            font-style:italic;
            font-weight:300;
            white-space: nowrap;
            @include media-breakpoint-up(lg)
            {
                margin-left: 8px;
            }
            svg
            {
                margin-left: 2px;
            }
        }

        @include media-breakpoint-up(lg)
        {
            .results-filters
            {
                //margin-bottom:1.5rem;
                //margin-top:0;
            }
        }

        @include media-breakpoint-only(lg)
        {
            padding-left: $grid-gutter-width/2;
            .col-12
            {
                padding-left:0; 
            }
        }

        @include media-breakpoint-up(xl)
        {
        }

        .filters.mobile-only {
            display: flex;
            flex-flow: column;

            @include media-breakpoint-up(sm) {
                flex-flow: initial;
            }

            .filter-availability, .filter-refundable {
                font-size: 16px;
                font-weight: 500;
                
                @include media-breakpoint-up(sm) {
                    margin: 20px 0 30px;
                }

                &-info {
                    p {
                        margin-bottom: 0;
                    }
                }
            }

            .filter-availability {
                @include media-breakpoint-up(sm) {
                    flex-flow: initial;
                }
            }
        }

        .filter-availability,
        .filter-refundable
        {
            display:flex;
            align-items:center;
            font-size: 13px;
            width:100%;
            margin-bottom:16px;
            &-info
            {
                padding-left:20px;
                flex:1;
                order:2;
            }
            label
            {
                margin:0;
            }
            &:first-child
            {
                margin-top:20px;
            }
        }

        .scroller
        {
            width:100%;
            &-wrap
            {
                position:relative;
                display:block;
                width:100%;
                height:100%;
            }
        }

        .ghost-pad
        {
            position:absolute;
            opacity:0.5;
            z-index: 1;
            opacity:0;
            z-index: -1;
            transform:translateY(-9999px);
        }
    }
</style>