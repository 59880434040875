<template>
    <div class="property-pad searchSuggestion">
        <div class="property-pad__inner">
            <div class="property-pad__info">
                <div class="property-pad__thumb">
                    <div class="property-pad__thumb-bg" :style="'background-image:url(/assets/images/no-dispo-2.jpg);'"></div>
                </div>
                <div class="boxed">
                    <p v-html="label"></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'search-suggestion',
    props: 
    {
        label: String,
    },
    methods:
    {
        search()
        {
            this.mixinSendMutation('setMatchLevel', 'nearby')
            this.mixinSendMutation('setTriggerSearch', true)
            this.$parent.$parent.$parent.$parent.getResults()
        }
    }
}
</script>

<style lang="scss">
    
    
    

    .searchSuggestion
    {
        .boxed
        {
            display: flex;
            align-items: center;
            justify-content: center;

            background:rgba($tertiary,0.06);
            font-size: 20px;
        }
        .property-pad__thumb
        {
            min-height:180px !important;
        }
        .property-pad__inner
        {
            background-color:rgba($white,0.75);
            *
            {
                background-color:transparent;
            }
        }
        .property-pad__thumb-bg
        {
            position:relative;
            display:block;
            height:100%;
            width:calc(100% - 10px);
            transition:$transition-fade;
            background-size:cover;
            background-position:center center;
            z-index: 2;
            border-top-right-radius: $border-radius-lg;
            border-bottom-right-radius: $border-radius-lg;
            margin-right:10px;
        }
    }
</style>