<template>
    <div :class="'custom-switch'">
        <input type="checkbox" :id="'custom-switch-'+switchUniqueKey" v-bind:key="status" v-model="checked" @change="switchChange(checked)" />
        <label :for="'custom-switch-'+switchUniqueKey">
            <span class="switch-on">
                <svg width="23" height="17" viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M21.685 3.82893L20.257 5.25691L14.019 11.4949C12.711 12.7969 11.407 14.1009 10.107 15.4069C9.69371 15.8077 9.14066 16.0318 8.56498 16.0318C7.98931 16.0318 7.43626 15.8077 7.02299 15.4069C6.76699 15.1499 6.51099 14.894 6.25999 14.639C4.45999 12.8297 2.65665 11.0226 0.849985 9.21797C0.451837 8.80679 0.231272 8.25551 0.23588 7.68318C0.240488 7.11084 0.469897 6.56323 0.874613 6.15852C1.27933 5.7538 1.8269 5.52445 2.39924 5.51985C2.97158 5.51524 3.52279 5.73583 3.93397 6.13398C4.18997 6.38998 4.44597 6.64493 4.69697 6.90193C5.9883 8.19326 7.27765 9.48629 8.56498 10.781L14.689 4.65693L18.601 0.744944C19.0122 0.346796 19.5634 0.1262 20.1357 0.130808C20.7081 0.135416 21.2556 0.364886 21.6603 0.769602C22.0651 1.17432 22.2945 1.72192 22.2991 2.29426C22.3037 2.8666 22.0831 3.41775 21.685 3.82893Z" fill="white"/>
                </svg>
            </span>
            <span class="switch-off">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M10.543 8.18794L14.533 4.19893C14.8973 3.82172 15.0989 3.31658 15.0943 2.79219C15.0898 2.2678 14.8794 1.76616 14.5086 1.39534C14.1378 1.02452 13.6362 0.814203 13.1118 0.809646C12.5874 0.805089 12.0822 1.00661 11.705 1.37093L7.71498 5.35994L3.72496 1.37093C3.54047 1.1799 3.3198 1.02762 3.07579 0.922805C2.83178 0.817987 2.56935 0.762759 2.30379 0.760452C2.03823 0.758144 1.77486 0.808694 1.52907 0.909255C1.28327 1.00982 1.05996 1.15836 0.872176 1.34614C0.684391 1.53393 0.535879 1.75721 0.435317 2.00301C0.334755 2.2488 0.284175 2.51223 0.286483 2.77779C0.288791 3.04334 0.343957 3.30575 0.448776 3.54976C0.553594 3.79377 0.705967 4.01443 0.896987 4.19893L4.88698 8.18794L0.896987 12.1779C0.705967 12.3624 0.553594 12.5831 0.448776 12.8271C0.343957 13.0711 0.288791 13.3336 0.286483 13.5992C0.284175 13.8648 0.334755 14.1281 0.435317 14.3739C0.535879 14.6196 0.684391 14.8429 0.872176 15.0307C1.05996 15.2185 1.28327 15.367 1.52907 15.4676C1.77486 15.5682 2.03823 15.6187 2.30379 15.6164C2.56935 15.6141 2.83178 15.559 3.07579 15.4542C3.3198 15.3494 3.54047 15.197 3.72496 15.0059L7.71498 11.0159L11.705 15.0059C12.0822 15.3703 12.5874 15.5719 13.1118 15.5673C13.6362 15.5628 14.1378 15.3525 14.5086 14.9816C14.8794 14.6108 15.0898 14.1092 15.0943 13.5848C15.0989 13.0604 14.8973 12.5551 14.533 12.1779L10.543 8.18794Z" fill="white"/>
                </svg>
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'custom-switch',
    props:
    {
        status: Boolean,
        switchUniqueKey: String
    },
    data(){
        return {
            checked: this.status
        }
    },
    methods:
    {
        switchChange(checked)
        {
            this.$emit('switch-change',checked)
        }
    },
    watch: {
        status: function () {
            this.checked = this.status
        }
    },
}
</script>

<style lang="scss">
    
    
    

    .custom-switch
    {
        $switch-width: 70px;
        $switch-height: 35px;
        $pad: 4px;

        margin:0 0 0 0;
        padding:0 0 0 0 !important;

        label
        {
            border-radius: $border-radius-lg;
            margin:0;
            cursor:pointer;
            position:relative;
            //width:100%;
            //height:100%;
            width:$switch-width;
            height:$switch-height;

            transition:$transition-base;
            &:after
            {
                content:"";
                position:absolute;
                height:calc(100% - #{2*$pad});//$switch-height - 8px;
                width:calc(50% - #{2*$pad});//$switch-height - 8px;
                border-radius: 50%;
                margin:4px;
                background:$white;
                left:0;
                right:auto;
                top:0;
                transition:$transition-base;
            }
        }
        svg
        {
            width:calc(15px * 100 / 110);//16px;
            height:calc(15px * 100 / 110);//16px;
        }
        .switch-on
        {
            position:absolute;
            left:calc(14 * 100% / 110);//14px;
            top:50%;
            transform:translateY(-55%);
            transition:$transition-base;
        }
        .switch-off
        {
            position:absolute;
            right:calc(14 * 100% / 110);//14px;
            top:50%;
            transform:translateY(-55%);
            transition:$transition-base;
        }
        input
        {
            display:none;
        }
        input ~ label
        {
            background:rgba($black,0.3);
            .switch-on
            {
                opacity:0;
            }
            .switch-off
            {
                opacity:1;
            }
        }

        input:checked ~ label
        {
            background:$light-green;
            .switch-on
            {
                opacity:1;
            }
            .switch-off
            {
                opacity:0;
            }            
            &:after
            {
                //transform:translateX($switch-width - $switch-height)
                margin-left:calc(100% - #{$pad});
                transform:translateX(-100%);
            }
        }


    }
</style>