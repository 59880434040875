<template>
    <div class="property-pad">
        <div class="property-pad__inner">
            <div class="property-pad__info">
                <div class="property-pad__thumb" @click="goToPropertyPage()" >
                    <div v-if="index<4" class="property-pad__thumb-content">
                        <div class="property-pad__thumb-bg" :style="'background-image:url('+property.thumbNailUrl+')'"></div>
                        <div class="spinner__box"><Spinner /></div>
                    </div>
                    <div v-else class="property-pad__thumb-content">
                        <div class="property-pad__thumb-bg" :style="'background-image:url('+property.thumbNailUrl+')'"></div>
                        <div class="spinner__box"><Spinner /></div>
                    </div>
                    <div v-if="showLite" class="property-pad__lite">
                        <LiteFavorite :variant="'compact'" :property="property.lite"/>
                        <LiteVisited :variant="'compact'" :property="property.lite"/>
                    </div>
                </div>

                <div class="boxed">
                    <div class="desc">
                        <!--<LiteFavorite v-if="showLite" :property="property.lite"/>
                        <LiteVisited v-if="showLite" :property="property.lite"/>-->
                        <h5 @click="goToPropertyPage()" class="property-pad__name">
                            <span class="bordered">{{property.name}}</span> <div v-if="property.hotelRating" class="property-pad__scorestars"><!--<Stars :hotelRating="parseFloat(property.hotelRating)" />--></div>
                        </h5>
                        <div class="property-pad__city pointer " @click="goToPropertyMap()">
                            <SvgPin /> {{property.address1}}, {{property.city}}
                        </div>

                        <div class="property-pad__proximity" v-if="property.proximityDistance >= 0 && geo.matchLevel == 'point'">
                            <SvgProximity /> {{property.proximityDistance}} km dal punto selezionato
                        </div>
                        <div class="property-pad__proximity" v-else-if="property.proximityDistance >= 0">
                          <SvgProximity /> {{property.proximityDistance}} km dal centro di {{geo.label}}
                        </div>

                        <PropertyRates
                            :score_description="property.score_description"
                            :salabamScore="property.salabamScore"
                        />
                        <div class="property-pad__amenities" v-if="false">
                            <span v-for="(value) in idState.idAmenities" v-bind:key="value" :class="'amenity '+(icons['propertyAmenities'][value] !== undefined ? icons['propertyAmenities'][value] : 'icon-default')">
                                {{amenitiesLabels[value].label}}
                            </span>
                        </div>
                        <p class="property-pad__desc" v-show="showShortDescription">{{property.shortDescription}}</p>
                    </div>
                    <div class="side">

                        <PropertyPrices
                            @go-to-property="goToPropertyPage()"
                            :uniqueId="property.property_id"
                            :additionalInfo="{}"
                            :disableUnavailable="false"
                            :live_price="property.live_price"
                            :btnText="'Scopri'"
                        />

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Vue from 'vue'
import { IdState } from 'vue-virtual-scroller'
import Spinner from '../../atoms/spinner'
import PropertyRates from '../../blocks/property/property-rates'
//import Stars from '../../atoms/stars'
import PropertyPrices from '../../blocks/property/property-prices'
import SvgPin from '../../svg/pin'
import SvgProximity from '../../svg/proximity'
import LiteVisited from '@/components/atoms/lite-visited'
import LiteFavorite from '@/components/atoms/lite-favorite'

export default {
    name: 'property',
    components:
    {
        SvgPin,
        SvgProximity,
        PropertyRates,
        PropertyPrices,
        Spinner,
        //Stars,
        LiteVisited,
        LiteFavorite,
    },
    props:
    {
        property: Object,
        index: Number,
        showShortDescription: {
            type: Boolean,
            default: false,
        },
        showLite: {
            type: Boolean,
            default: true,
        }
    },
    computed: {
        occupancy: {
            get() { return this.$store.state.occupancy },
            set(value) { this.mixinSendMutation('setOccupancy',value) }
        },
        dates: {
            get() { return this.$store.state.dates },
            set(value) { this.mixinSendMutation('setDates',value) }
        },
        geo: {
            get() { return this.$store.state.geo },
            set() {}
        },
    },
    mounted()
    {
        let amenitiesKeys = Object.keys(this.idState.idAmenities)
        let amenitiesArray = []
        amenitiesKeys.forEach(element => {
            amenitiesArray.push(element)
        })
        this.idState.idAmenitiesArray = amenitiesKeys.slice(0,3)
    },
    data(){
        // attenzione: poiché è utilizzato all'interno di un virtual scroller, le data() sono tutte condivise tra gli items scrollabili
        // quindi vanno evitate oppure contestualizzate tramite opportuno mixin che sostituisce l'oggetto di data() con un altro scoped nell'oggetto
        // docs: https://github.com/Akryum/vue-virtual-scroller#idstate
        return {
            //amenitiesArray:     [],
            icons:              Vue.prototype.$config.icons,
            amenitiesLabels:    Vue.prototype.$config.amenities,
        }
    },
    mixins: [
        IdState({
            // You can customize this
            idProp: vm => vm.property.property_id,
        }),
    ],
    // This replaces data () { ... }
    idState () {
        return {
            idAmenities: this.property.amenities,
            idAmenitiesArray: [],
        }
    },
    methods:
    {
        goToPropertyPage()
        {
            this.mixinSendMutation('setScroll', window.pageYOffset)
            this.mixinSendMutation('setPropertyDetail', this.property)
            this.mixinGoTo('property',{
                propertyId: this.property.property_id,
                propertyPad: this.property,
                propertyName: this.property.name,
                newWindow: false
            })
        },
        goToPropertyMap()
        {
            this.mixinSendMutation('setPropertyDetail', this.property)
            this.mixinGoTo('map-detail',{
                slug: (this.property.name).replace(/ /g, '-'),
                title: this.property.name,
                propertyId: parseInt(this.property.property_id),
                property: this.property
            })            
        }
    }
}
</script>

<style lang="scss">
    .property-pad {

        *[lazy=loaded] {
            .spinner__box
            {
                display:none;
            }
        }

        display:flex;
        flex-direction: column;
        justify-content: space-between;
        height:100%;

        &__inner
        {
            box-shadow:3px 3px 10px 0px rgba(0,0,0,0.15);
            border-radius: $border-radius-lg;
            overflow:hidden;
            height:100%;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            background:$white;
        }

        &__thumb
        {
            transition:$transition-fade;
            background-size:cover;
            background-position:center center;
            width:100%;
            position:relative;
            height:160px;
            cursor: pointer;

            .property-pad__thumb-bg
            {
                position:relative;
                display:block;
                height:100%;
                width:calc(100% - 10px);
                transition:$transition-fade;
                background-size:cover;
                background-position:center center;
                z-index: 2;
                border-top-right-radius: $border-radius-lg;
                border-bottom-right-radius: $border-radius-lg;
                margin-right:10px;
            }

            .spinner__box
            {
                z-index:1;
            }

            position:relative;
            border-radius: $border-radius-lg;

            &:before
            {
                content:"";
                @include customize-background-color("primary");
                @include customize-gradient(vertical, important);
                position:absolute;
                top:0;
                right:0;
                height:100%;
                width: 100%;
                border-top-right-radius: $border-radius-lg;
                border-bottom-right-radius: $border-radius-lg;
                z-index: 1;
            }

            .spinner__box
            {
                position:absolute;
                top:50%;
                transform:translateY(-50%);
                left:0;
                right:0;
                margin:auto;
                text-align:center;
                display:flex;
            }

            img
            {
                display:none;
                width:100%;
                visibility:hidden;
            }

            &-content
            {
                display:block;
                width:100%;
                height:100%;
                position:relative;
            }
        }

        &__lite
        {
            position: absolute;
            top:10px;
            left:10px;
            z-index:10;
        }

        &__scorestars
        {
            position:relative;
            display:block;
            min-width: 100px;
            .stars
            {
                transform:translateY(-2px);
                justify-content: flex-start;
                span
                {
                    width:16px;
                    margin-right:2px;
                    @include media-breakpoint-up(xl)
                    {
                        width:20px;
                    }
                }
            }
            @include media-breakpoint-up(xl)
            {
                display:inline-block;
                margin-left:0.75rem;
                text-align:center;
                .stars
                {
                    justify-content: center;
                }
            }
        }

        &__info
        {
            .boxed
            {
                padding:$grid-gutter-width/4 $grid-gutter-width/3;
                @include media-breakpoint-up(lg)
                {
                    padding:$grid-gutter-width/3 $grid-gutter-width/2;
                }
            }
            .desc
            {
                .property-pad__desc
                {
                    font-size:13px;
                    color:$gray-600;
                    margin-top:1rem;
                    padding-top:1rem;
                    border-top:1px solid $gray-300;
                    line-height: 120%;
                    font-weight:400;
                }
                @include media-breakpoint-up(xl)
                {
                    padding-right:$grid-gutter-width/3;
                    .property-pad__desc
                    {
                        font-size:15px;
                        margin-bottom:0;
                    }
                }

            }
        }

        &__name
        {
            @include customize-color("tertiary");

            .bordered
            {
                @include customize-bordered-element("secondary");
                display: flex;
                line-height: normal;
            }

            margin:0;
            padding:0;
            font-weight:bold;
            font-size:18px;
            line-height:100%;
            font-weight:600;
            cursor: pointer;

            @include media-breakpoint-up(lg)
            {
                display:flex;
                justify-content:space-between;
                align-items:baseline;
                font-size:20px;
            }
            @include media-breakpoint-up(xl)
            {
                font-size:22px;
            }
        }

        &__city {
          position:relative;
          font-size:12px;
          font-weight:400;
          @include customize-color("cta");

          @at-root #map-list & {
            padding-left:22px;

            svg {
              height:auto;
              width:14px;
              margin-right:7px;
              margin-left:10px;
              path {
                @include customize-color("cta");
              }
            }
          }

          @include media-breakpoint-up(xl) {
            font-size:14px;
          }
        }

        &__proximity
        {
            margin:4px auto;
            margin-left:32px;
            font-size: 14px;
            font-weight: 500;
            display:flex;
            align-items: flex-start;
            margin-top: 16px;
            line-height: normal;

            @include customize-color("secondary");
            svg
            {
                min-width:13px;
                max-width:13px;
                height:auto;
                margin-left:2px;
                margin-right:6px;
                margin-top:4px;
                path
                {
                    @include customize-fill("secondary");
                }
            }
            @include media-breakpoint-up(xl)
            {
                margin-left:32px;
            }
        }

        &__rates
        {
            margin-top: 13px;
            margin-left: 32px;
        }

        &__amenities
        {
            margin-top:0.5rem;
            display:flex;
            flex-wrap:wrap;
            @include media-breakpoint-down(lg)
            {
                .amenity
                {
                    &:nth-of-type(1n+3) {display: none;}
                }
                &:after
                {
                    content:"...";
                    display:block;
                    text-align: left;
                    color:$green;
                }
            }
            @include media-breakpoint-down(sm)
            {
                margin-top:0;
                .amenity
                {
                    width:50%;
                    font-weight:500;
                    font-size:12px;
                }
                .amenity:not(:first-child)
                {
                    display: none;
                }
            }
        }
/*
        &__city,
        &__proximity,
        &__rates
        {
            border:1px solid red;
            width:100%;
            margin:4px auto;
            margin-left:32px;
            @include media-breakpoint-up(xl)
            {
                margin:16px auto;
                margin-left:32px;
            }
        }
*/
        @include media-breakpoint-up(md)
        {
            &__name
            {
            }

            &__room
            {
                font-size:11px;
                line-height: 110%;
                margin:0.35rem 0;
            }

            &__info
            {
                display:flex;
            }

            &__thumb,
            &__thumb-bg
            {
                width:240px;
                min-height:150px;
                height:inherit;
            }

            &__lite
            {
                top:1rem;
                left:1rem;
            }

            .boxed
            {
                width:calc(100% - 240px);
                display:flex;
                position:relative;
                overflow:hidden;
                .desc
                {
                    width:70%;
                }
                .side
                {
                    width:30%;
                    text-align:center;
                    position:relative;
                    padding-left:20px;
                    

                    @include media-breakpoint-up(xl)
                    {
                        display: flex; 
                        align-items: center;
                        justify-content: center;
                    }

                    &:before
                    {
                        content:"";
                        height:200%;
                        border-left:$dashed-border;
                        position:absolute;
                        left:0;
                        top:-50%;
                    }
                }
            }

        }

        @include media-breakpoint-only(lg)
        {
            .boxed
            {
                flex-direction:column;
                .desc
                {
                    width:100%;
                }
                .side
                {
                    margin-left:0;
                    padding-left:0;
                    &:before {display:none;}
                    width:100%;
                    #property-prices
                    {
                        flex-direction:row;
                        flex-wrap:nowrap;
                        hr
                        {
                            display:none;
                        }
                        button
                        {
                            margin-top: 0;
                        }
                        .property__resume,
                        .property-prices-total,
                        .property-prices-per-night
                        {
                            margin-bottom:0;
                        }
                        
                        .property-prices-occupancy{width:30%}
                        button{width:30%;padding:8px 32px}
                    }
                }
            }
        }


        @include media-breakpoint-up(xl)
        {
            &__thumb,
            &__thumb-bg
            {
                width:450px;
                min-height:260px;
            }
            .boxed
            {
                width:calc(100% - 450px);
            }
        }

        //intervento mirato per mid screen (1200px - 1599px)
        @include media-breakpoint-only(xl)
        {
            &__thumb,
            &__thumb-bg
            {
                width:300px;
                min-height:170px;
            }
            .boxed
            {
                width:calc(100% - 300px);
                .desc
                {
                }
                .side
                {
                }
                .property-prices-total
                {
                    font-size: 24px !important;
                }
            }
        }
    }
</style>