<template>
    <div v-if="this.visited" :class="'lite__visited lite__' + variant + ' ' + (visited ? 'active' : '') + ' lite-tooltip'" :data-text="(variant == 'compact')? 'Già visto' : ''"><!-- tooltip solo compact -->
        <div class="lite__icon"><font-awesome-icon icon="eye"/></div>
        <div class="lite__copy">già visto</div>
    </div>
</template>

<script>
import Vue from 'vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faEye } from '@fortawesome/free-solid-svg-icons'
library.add(faEye)
Vue.component('font-awesome-icon', FontAwesomeIcon)

export default {
    name: 'visited',
    props:{
        property: Object,
        variant:
        {
            type: String,
            default: 'full' // 'compact' | 'full'
        },
    },
    components:{
    },
    data()
    {
        return {
            history: this.$store.state.history,
            visited: false,
        }
    },
    watch:
    {
        visitedLength: function()
        {
            this.checkIsVisited()
        }
    },
    mounted()
    {
        this.checkIsVisited()
    },
    methods:
    {
        checkIsVisited()
        {
            let findVisited,
                _this = this

            if(this.history.properties == undefined)
            {
                this.visited = false
                return
            }

            findVisited = this.history.properties.filter(function(property){
                return property.property.propertyId == _this.property.propertyId
            }).length
            
            this.visited = (findVisited !== 0)
        }
    }
}
</script>

<style lang="scss">
    
    
    
    .visited
    {
    }
</style>