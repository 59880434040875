<template>
    <div class="active-filters" v-if="selectedAmenities.length | selectedCategories.length | selectedRates.length">
        <!--<span class="geo" v-if="label">{{label}}</span>-->
        <b-badge variant="secondary"
            v-for="(category,index) in selectedCategories" v-bind:key="'cat-'+index"
        >
            {{categories[category]}}
            <span class="remove-filter" @click="removeCategory(category)">X</span>
        </b-badge>
        <b-badge variant="secondary"
            v-for="(amenity,index) in selectedAmenities" v-bind:key="'am-'+index"
        >
            {{amenities[amenity].label}}
            <span class="remove-filter" @click="removeAmenity(amenity)">X</span>
        </b-badge>
        <b-badge variant="gold"
            v-for="(rate,index) in selectedRates" v-bind:key="'rat-'+index"
        >
            {{rate}} stelle
            <span class="remove-filter" @click="removeRate(rate)">X</span>
        </b-badge>
    </div>
</template>

<script>
import Vue from 'vue'
export default {
    name: 'active-filters',
    components:
    {
    },
    data()
    {
        return {
            categories: Vue.prototype.$config.salabamCategories,
            amenities: Vue.prototype.$config.amenities,
            //label: ''
        }
    },
    computed: {
        /*
        geo: {
            get() { return this.$store.state.geo },
            set(value) { this.mixinSendMutation('setGeo',value) }
        },*/
        selectedAmenities: {
            get() {
                return this.$store.state.amenities
            },
            set(value) {
                this.mixinSendMutation('setAmenities',value)
            }
        },
        selectedCategories: {
            get() {
                return this.$store.state.categories
            },
            set(value) {
                this.mixinSendMutation('setCategories',value)
            }
        },
        selectedRates: {
            get() {
                return this.$store.state.rates
            },
            set(value) {
                this.mixinSendMutation('setRates',value)
            }
        },            
    },
    methods:
    {
        removeCategory(category)
        {
            //this.$parent.$parent.resetResults()
            if(this.$parent.$parent.resetResults)
            {
                this.$parent.$parent.resetResults()
            }
            if(this.$parent.resetResults)
            {
                this.$parent.resetResults()
            }
            this.selectedCategories.splice( this.selectedCategories.indexOf(category), 1 )
            this.mixinSendMutation('setCategories',this.selectedCategories)
            
            //this.$parent.$parent.getResults()
            if(this.$parent.$parent.getResults)
            {
                this.$parent.$parent.getResults()
            }
            if(this.$parent.getResults)
            {
                this.$parent.getResults()
            }
        },
        removeAmenity(amenity)
        {
            this.selectedAmenities.splice( this.selectedAmenities.indexOf(amenity), 1 )
            this.mixinSendMutation('setAmenities',this.selectedAmenities)

            //this.$parent.$parent.filterChanged()
            if(this.$parent.$parent.filterChanged)
            {
                this.$parent.$parent.filterChanged()
            }
            if(this.$parent.filterChanged)
            {
                this.$parent.filterChanged()
            }
        },
        removeRate(rate)
        {
            this.selectedRates.splice( this.selectedRates.indexOf(rate), 1 )
            this.mixinSendMutation('setRates',this.selectedRates)

            //this.$parent.$parent.filterChanged()
            if(this.$parent.$parent.filterChanged)
            {
                this.$parent.$parent.filterChanged()
            }
            if(this.$parent.filterChanged)
            {
                this.$parent.filterChanged()
            }
        },
    },
    mounted()
    {
        //this.label = this.geo.label
    }
}
</script>

<style lang="scss">
    
    
    

    .active-filters
    {
        margin-bottom:2rem;
        .geo
        {
            font-weight: 300;
            margin-right:6px;
        }
        .badge
        {
            margin-right:8px;
            font-weight: 500;
            padding:4px 8px;
            font-size:12px;
            text-transform:uppercase;
            position:relative;
            padding-right:24px;
            transition:$transition-base;
            cursor:pointer;
            &:hover
            {
                opacity:0.75;
            }            
        }
        .remove-filter
        {
            background:rgba($white,0.35);
            width:16px;
            height:16px;
            display: flex;
            border-radius: 50%;
            position:absolute;
            right:2px;
            top:50%;
            transform:translateY(-50%);
            text-align: center;
            justify-content: center;
            align-items: center;
            align-items: center;
            font-size:11px;
            font-weight: 300;
        }
    }
</style>